exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-blueberry-oatmeal-cookies-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/home/runner/work/healthy-men-cooking/healthy-men-cooking/blog/blueberry-oatmeal-cookies/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-blueberry-oatmeal-cookies-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-chili-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/home/runner/work/healthy-men-cooking/healthy-men-cooking/blog/chili/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-chili-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-grilled-shrimp-avocado-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/home/runner/work/healthy-men-cooking/healthy-men-cooking/blog/grilled-shrimp-avocado/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-grilled-shrimp-avocado-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-quinoa-salad-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/home/runner/work/healthy-men-cooking/healthy-men-cooking/blog/quinoa-salad/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-quinoa-salad-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-steak-fajitas-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/home/runner/work/healthy-men-cooking/healthy-men-cooking/blog/steak-fajitas/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-steak-fajitas-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-tuna-salad-yogurt-index-mdx": () => import("./../../../src/templates/blog-post.jsx?__contentFilePath=/home/runner/work/healthy-men-cooking/healthy-men-cooking/blog/tuna-salad-yogurt/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-blog-tuna-salad-yogurt-index-mdx" */),
  "component---src-templates-legal-document-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-legal-disclosure-index-mdx": () => import("./../../../src/templates/legal-document.jsx?__contentFilePath=/home/runner/work/healthy-men-cooking/healthy-men-cooking/legal/disclosure/index.mdx" /* webpackChunkName: "component---src-templates-legal-document-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-legal-disclosure-index-mdx" */),
  "component---src-templates-legal-document-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-legal-privacy-index-mdx": () => import("./../../../src/templates/legal-document.jsx?__contentFilePath=/home/runner/work/healthy-men-cooking/healthy-men-cooking/legal/privacy/index.mdx" /* webpackChunkName: "component---src-templates-legal-document-jsx-content-file-path-home-runner-work-healthy-men-cooking-healthy-men-cooking-legal-privacy-index-mdx" */)
}

